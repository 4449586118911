import React, { useState } from 'react'

import * as Styled from './styles/AudienceCampaignPageHero.styles'
import { RegionContainer } from '../styles/Utility.styles'
import { DISPLAY_WIDTH } from '../../util/constants'

const TextRegion = ({ pageTitle, heroText }) => (
  <Styled.HeroTextRegion>
    <Styled.HeroTextGrid>
      <Styled.HeroTitle>{pageTitle}</Styled.HeroTitle>
      <Styled.HeroBody>{heroText}</Styled.HeroBody>
    </Styled.HeroTextGrid>
  </Styled.HeroTextRegion>
)

const ImageRegion = ({ heroImage }) => (
  <Styled.ImageBackground>
    <Styled.HeroImageGrid>
      <Styled.HeroImage>
        <source
          media={`(max-width: ${DISPLAY_WIDTH.tabletL - 1}px)`}
          srcSet={heroImage.thumbnails.mobile.url}
        />
        <source
          media={`(min-width: ${DISPLAY_WIDTH.tabletL}px)`}
          srcSet={heroImage.url}
        />
        <img src={heroImage.url} alt={heroImage.alt} />
      </Styled.HeroImage>
    </Styled.HeroImageGrid>
  </Styled.ImageBackground>
)

const AudienceCampaignPageHero = ({ pageTitle, heroText, heroImage }) => (
  <>
    <TextRegion pageTitle={pageTitle} heroText={heroText} />
    <ImageRegion heroImage={heroImage} />
  </>
)

export default AudienceCampaignPageHero
