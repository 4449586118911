import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import AudienceCampaignPageHero from '../components/Heroes/AudienceCampaignPageHero'
import SliceRenderer from '../components/ContentBlocks/SliceRenderer'
import { BottomLineGoogleAd } from '../components/styles/GoogleAdsCommon.styles'
import GoogleAdBlock from '../components/ContentBlocks/GoogleAdBlock'

export const query = graphql`
  query AudienceCampaignPageQuery($id: String) {
    prismicGeneralSiteSettings {
      data {
        excluded_documents {
          excluded_document {
            uid
          }
        }
        audience_page_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
        audience_page_interstitial_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
      }
    }
    prismicAudienceCampaignPage(id: { eq: $id }) {
      id
      uid
      data {
        meta_description
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        facebook_image {
          url
        }
        twitter_image {
          url
        }
        hero_image {
          url
          alt
          thumbnails {
            mobile {
              url
            }
          }
        }
        hero_text {
          text
          richText
        }
        page_title {
          text
          richText
        }
        body {
          ... on PrismicAudienceCampaignPageDataBodyAccordionBlock {
            id
            slice_type
            primary {
              section_header {
                text
              }
              bottom_padding_adjustment
            }
            items {
              content {
                richText
              }
              block_header {
                text
              }
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyAdvancedImageBlock {
            id
            slice_type
            primary {
              layout_option
              text_content {
                richText
              }
              cta_text {
                text
              }
              cta_link {
                uid
                link_type
                id
                type
                url
              }
              header_text {
                text
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyAuthorBlock {
            id
            slice_type
            primary {
              author {
                document {
                  ... on PrismicGuestAuthor {
                    id
                    type
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicNpfAuthor {
                    id
                    type
                    uid
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      bio {
                        richText
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyBasicImageBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              image_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyBodyContent {
            id
            slice_type
            primary {
              body_copy {
                richText
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyCalendarOfEventsBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              layout_style
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyCustomForm {
            id
            slice_type
            primary {
              form_title
              form_body {
                richText
              }
              form_embed_code {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyEventBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
            items {
              event {
                id
                document {
                  ... on PrismicEvent {
                    data {
                      end_date
                      event_date
                      event_city
                      event_state
                      end_time
                      start_time
                      event_venue
                      event_address_line_1
                      event_address_line_2
                      event_image {
                        alt
                        url
                        thumbnails {
                          mobile {
                            url
                          }
                        }
                      }
                      event_title {
                        text
                      }
                      event_description {
                        text
                      }
                      event_link {
                        id
                        link_type
                        uid
                        url
                        type
                      }
                      event_link_text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyGoogleAd {
            id
            slice_type
            primary {
              google_ad_tag {
                document {
                  ... on PrismicGoogleAdTag {
                    id
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyGridBlock {
            id
            slice_type
            primary {
              layout_type
              bottom_padding_adjustment
            }
            items {
              cta_link_text
              cta_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              summary {
                richText
              }
              title {
                text
              }
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyIconTextBlock {
            id
            slice_type
            primary {
              header {
                richText
              }
              body_text {
                richText
              }
              bottom_padding_adjustment
            }
            slice_type
            items {
              cta_text
              item_title {
                richText
              }
              item_icon {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              item_body_text {
                richText
              }
              cta_link {
                id
                url
                uid
                type
                link_type
              }
            }
          }
          ... on PrismicAudienceCampaignPageDataBodySponsorsBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
            items {
              sponsor_logo {
                alt
                url
              }
              sponsor_link {
                url
                uid
                link_type
                id
              }
            }
            primary {
              intro_text {
                richText
              }
              header {
                text
              }
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyStatisticsBlock {
            id
            slice_type
            primary {
              citation
              statistic_prompt
              header {
                text
              }
              subhead {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicAudienceCampaignPageDataBodyVideoBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              schema_publish_date
              schema_video_description
              video_duration_hours
              video_duration_minutes
              video_duration_seconds
              transcription_link {
                url
                uid
                type
                link_type
              }
              video_title {
                text
              }
              video_thumbnail {
                url
              }
              video_link {
                embed_url
              }
              bitmovin_stream_id
              bottom_padding_adjustment
            }
          }
        }
      }
    }
  }
`

const AudienceCampaignPage = ({ data }) => {
  const doc = data.prismicAudienceCampaignPage
  if (!doc) {
    return null
  }
  const pageTitle = doc.data.page_title?.text ?? ''
  const heroText = doc.data.hero_text?.text ?? ''
  const twitterImage = doc.data.twitter_image?.url
    ? doc.data.twitter_image
    : doc.data.hero_image?.url
    ? doc.data.hero_image
    : null
  const facebookImage = doc.data.facebook_image?.url
    ? doc.data.facebook_image
    : doc.data.hero_image?.url
    ? doc.data.hero_image
    : null
  const seoTitle = doc.data.meta_title?.text
    ? doc.data.meta_title.text
    : doc.data.page_title?.text
    ? doc.data.page_title.text
    : null
  const seo_embed_code = doc.data.seo_embed_code?.text ?? null
  const settingsNode = data.prismicGeneralSiteSettings.data

  const adBlockId = settingsNode?.audience_page_google_ad?.document.id
  const interstitialId =
    settingsNode?.audience_page_interstitial_ad_tag?.document.id
  const uid = doc.uid
  const isExcluded =
    settingsNode.excluded_documents.filter(
      item => item.excluded_document?.uid === uid
    ).length > 0

  return (
    <>
      <SEO
        seo={{
          description: doc.data.meta_description,
          keywords: doc.data.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
          isExcluded: isExcluded,
        }}
      />
      <Layout>
        <AudienceCampaignPageHero
          pageTitle={pageTitle}
          heroText={heroText}
          heroImage={doc.data.hero_image}
        />
        {doc.data.body &&
          doc.data.body.map(block => (
            <SliceRenderer key={block.id} block={block} />
          ))}
        {adBlockId && <BottomLineGoogleAd id={adBlockId} />}
        {interstitialId && <GoogleAdBlock id={interstitialId} />}
      </Layout>
    </>
  )
}

export default AudienceCampaignPage
